<template>
  <a-select
    :defaultValue="selectedLabel"
    :allowClear="true"
    @change="handleWilayasChange"
    style="width: 100%"
    v-decorator="[
      'wilaya_id',
      {
        rules: [
          {
            required: true,
            message: 'La wilaya d\'activité est obligatoire !',
          },
          { validator: checkEmptyValue },
        ],
      },
    ]"
    placeholder="Choisir une wilaya"
  >
    <a-select-option value="0">Choisir une wilaya</a-select-option>
    <a-select-option v-for="wilaya in data" :key="wilaya.code" :value="wilaya.id">{{
      wilaya.nom_fr
    }}</a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "WilayasList",
  props: {
    data: Array,
    selectedValue: { type: Number },
    mandatory: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleWilayasChange(selectedItem) {
      this.$emit("wilaya_change", selectedItem);
    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    },
  },
  computed: {
    selectedLabel() {
      if (isNaN(this.selectedValue)) 
        return undefined;

      const wilayas = this.data.filter(w => w.code == this.selectedValue)

      if (wilayas.length == 0)
        return undefined;
        
      return wilayas[0].nom_fr;
    },
  },
};
</script>
