var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      directives: [
        {
          name: "decorator",
          rawName: "v-decorator",
          value: [
            "wilaya_id",
            {
              rules: [
                {
                  required: true,
                  message: "La wilaya d'activité est obligatoire !"
                },
                { validator: _vm.checkEmptyValue }
              ]
            }
          ],
          expression:
            "[\n    'wilaya_id',\n    {\n      rules: [\n        {\n          required: true,\n          message: 'La wilaya d\\'activité est obligatoire !',\n        },\n        { validator: checkEmptyValue },\n      ],\n    },\n  ]"
        }
      ],
      staticStyle: { width: "100%" },
      attrs: {
        defaultValue: _vm.selectedLabel,
        allowClear: true,
        placeholder: "Choisir une wilaya"
      },
      on: { change: _vm.handleWilayasChange }
    },
    [
      _c("a-select-option", { attrs: { value: "0" } }, [
        _vm._v("Choisir une wilaya")
      ]),
      _vm._l(_vm.data, function(wilaya) {
        return _c(
          "a-select-option",
          { key: wilaya.code, attrs: { value: wilaya.id } },
          [_vm._v(_vm._s(wilaya.nom_fr))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }